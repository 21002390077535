var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"invisible md:visible inset-y-0 w-0 md:w-24 max-h-screen border-r border-bordergray text-textgray",class:{
      'hidden': !_vm.isSidebarOpen
    }},[_vm._m(0),_c('p',{staticStyle:{"float":"left","font-weight":"400","clear":"both","color":"white","font-size":"13px","opacity":"0.65","margin-left":"20px","margin-top":"2px","padding-top":"0px"}},[_vm._v(" "+_vm._s(_vm.operatorName)+" ")]),_c('nav',{staticClass:"grid overflow-hidden hover:overflow-y-auto clear-both mt-20",staticStyle:{"font-size":"15px"}},[_c('ul',_vm._l((_vm.links),function(link){return _c('li',{key:link.to},[_c('div',{staticClass:"grid items-center px-3 py-5 cursor-pointer",class:{
              'hover:bg-hoverLightHighlight hover:text-white': _vm.isHovering && !_vm.isActiveLink(link)
            },on:{"mouseover":function($event){_vm.isHovering = true},"mouseout":function($event){_vm.isHovering = false},"click":function($event){return _vm.handleSidebarItemClick(link)}}},[_c('img',{class:_vm.isActiveLink(link) ? 'opacity-100' : 'opacity-80',staticStyle:{"width":"32px","margin-left":"18px"},attrs:{"src":require(("../../assets/sidebar/" + (_vm.isActiveLink(link)?  link.imgNameSelected: link.imgName)))}})]),(_vm.expandedLink.to === link.to || _vm.isActiveLink(link))?_c('div',{staticClass:"grid-row sidebar-item__children"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"grid gap-y-5 text-textgray",class:link.children.length > 0 ? 'p-5 pl-2' : 'p-0',staticStyle:{"background":"rgba(0,0,0,0.2)"}},_vm._l((link.children),function(child,idx){return _c('router-link',{key:child.to,staticClass:"contents child-item cursor-pointer",attrs:{"to":{
                    name: child.to,
                  }}},[_c('div',{staticClass:"child-item__line-wrapper h-full col-start-1 col-end-2 mx-auto relative"},[_c('div',{staticClass:"h-full w-6 flex items-center justify-center",class:{
                        'hidden': idx === link.children.length - 1,
                      }},[_c('div',{staticClass:"child-item__line absolute h-full w-0.5 bg-white pointer-events-none"})]),_c('div',{staticClass:"child-item__dot w-1 h-1 absolute top-1/2 -mt-3 rounded-full bg-transparent",class:{
                        'bg-white': child.to === _vm.$route.name
                      }})]),_c('div',{staticClass:"col-start-3 col-end-10",class:{
                      'text-white': child.to === _vm.$route.name
                    },staticStyle:{"font-size":"13px","margin-left":"5px","font-weight":"300"}},[_vm._v(" "+_vm._s(child.name)+" ")])])}),1)])]):_vm._e()])}),0)]),_c('div',{staticClass:"absolute bottom-2 w-24 hover:bg-hoverLightHighlight"},[_c('div',{staticClass:"p-6 pt-2 cursor-pointer",on:{"click":function($event){return _vm.handleSidebarItemClick(_vm.userSettingsPageLink)}}},[_c('div',{staticStyle:{"position":"relative","float":"left"}},[_c('div',{staticClass:"uppercase bg-purple-300 rounded-full h-12 w-12 float-right text-white text-xl text-center",staticStyle:{"padding-top":"10px","margin-bottom":"10px"}},[_vm._v(" "+_vm._s(_vm.userInitals)+" ")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid grid-flow-col justify-between items-center text-textgray p-2 overflow-hidden mb-0 pb-0"},[_c('img',{staticClass:"w-20",attrs:{"src":require("../../assets/tasq_white_logo.png")}})])}]

export { render, staticRenderFns }